import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MenuCategory } from '../../../../ecomm/types/store-info.types';
import { Router } from "@angular/router";
import { AnalyticsService } from "../../../../ecomm/providers/legacy-providers/analytics.service";

@Component({
  selector: 'wri-category-based-menu',
  templateUrl: './category-based-menu.component.html',
  styleUrls: ['./category-based-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CategoryBasedMenuComponent {
  @Input() isGlobalMenu = false;
  @Input() public category!: MenuCategory;
  @Input() layout: string | undefined;
  @Input() public locationSlug: string | undefined;

  constructor(
    private router: Router,
    private analyticsService: AnalyticsService) {
  }

  navigateToCategoryPage(event: Event, categorySlug: string) {
    event.preventDefault();

    this.analyticsService.logGaEvent({
      event: 'category_button_clicked',
      category_name: this.category?.name
    });

    let url = `/location/${this.locationSlug}/menu/${categorySlug}`;
    if (this.isGlobalMenu) {
      url = `/menu/${categorySlug}`;
    }
    this.router.navigate([url]);
  }
}
