import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import { filter, Subscription, switchMap, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreInfo } from '../../../../ecomm/types/store-info.types';
import {
  CartFeature,
  CartFeatureState,
} from '../../../../ecomm/store/features/cart';
import {
  StoreInfoFeature,
  StoreInfoFeatureState,
} from '../../../../ecomm/store/features/store-info';
import {
  ILegacySeoService,
  LEGACY_SEO_SERVICE,
  structuredDataBreadcrumbList,
  structuredDataRestaurantFromStoreDetails,
} from '../../../../ecomm/providers/legacy-providers/seo.service';
import { ACTIVATED_ROUTE_SNAPSHOT } from '../../../common/resolvers/activated-route-snapshot.resolver';
import { LocationMenuPageService } from '../../../common/page-services/location-menu-page.service';
import { buildPdpSlugs } from '../../../../ecomm/workflows/store-info/store-info.utilities';
import { DOCUMENT } from '../../../../ecomm/providers/document/document.provider';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { WINDOW } from '../../../../ecomm/providers/window/window.provider';
import { addSpaceToBodyWithStickyFooter } from '../../../../ecomm/utils/dom-manipulations';
import {
  LocationDetailsFeature,
  LocationDetailsFeatureState,
} from '../../../../ecomm/store/features/location-details';
import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState,
} from '../../../../ecomm/store/features/regional-configuration';
import { PartialOutageModalComponent } from '../../../common';
import { HandoffMode } from '../../../../ecomm/types/selected-handoff-mode.types';
import { FeatureFlagService } from "../../../../ecomm/utils/feature-flag/feature-flag.service";

@Component({
  selector: 'wri-connected-location-menu',
  templateUrl: './connected-location-menu.component.html',
})
export class ConnectedLocationMenuComponent implements OnInit, OnDestroy {
  public storeInfoState: StoreInfoFeatureState | null = null;
  public cartState: CartFeatureState | null = null;
  public locationDetailsState: LocationDetailsFeatureState | null = null;
  public isLoading = true;
  public drivenToWinCategoryMenuFFEnabled = false;
  private subscription = new Subscription();
  private storeInfoState$ = this.store
    .select(StoreInfoFeature.selectStoreInfoState)
    .pipe(filter<StoreInfoFeatureState>(Boolean));
  private cartState$ = this.store
    .select(CartFeature.selectCartState)
    .pipe(filter<CartFeatureState>(Boolean));

  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  constructor(
    private store: Store,
    @Inject(LEGACY_SEO_SERVICE)
    private seoService: ILegacySeoService,
    @Inject(DOCUMENT)
    private document: Document,
    private route: ActivatedRoute,
    private router: Router,
    private pageService: LocationMenuPageService,
    private analyticsService: AnalyticsService,
    @Inject(WINDOW) private window: Window,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit(): void {
    addSpaceToBodyWithStickyFooter('menu-page');
    this.subscription.add(
      this.route.data
        .pipe(
          filter((data) => data[ACTIVATED_ROUTE_SNAPSHOT]),
          tap(() => (this.isLoading = true)),
          switchMap((data) => {
            return this.pageService.load$(data[ACTIVATED_ROUTE_SNAPSHOT]);
          })
        )
        .subscribe(() => {
          this.isLoading = false;
        })
    );

    this.subscription.add(
      this.storeInfoState$.subscribe((state) => {
        this.storeInfoState = state;
        if (state.storeInfo?.storeDetails) {
          this.seoService.setRobotsNoIndexNoFollow();
          this.seoService.setMetaData({
            title: `${state.storeInfo?.storeDetails?.name}, ${state.storeInfo?.storeDetails?.state} | Order Online | Wingstop`,
            description:
              `Order Wingstop online at your local ${state.storeInfo?.storeDetails?.name} restaurant! Wings, fries, sides, repeat. ` +
              "Wing flavors you'll crave even more next time than you did this time. Only at Wingstop.",
          });

          this.seoService.addStructuredData([
            structuredDataRestaurantFromStoreDetails(
              this.document,
              state.storeInfo.storeDetails
            ),
            structuredDataBreadcrumbList([
              {
                name: 'Home',
                url: this.document.location.origin,
              },
              {
                name: `${state.storeInfo.storeDetails.name} Menu`,
                url: this.document.location.href,
              },
            ]),
          ]);
        }
      })
    );

    this.subscription.add(
      this.cartState$.subscribe((state) => {
        this.cartState = state;
        if (this.cartState && this.storeInfoState && !this.isLoading) {
          this.redirectToPdpIfProductRouteParamFound(
            this.storeInfoState.storeInfo
          );
        }
      })
    );

    this.subscribeToLocationDetailsState();
    this.subscribeToRegionalConfigState();
    this.featureFlagService.getFeatureFlagValue(
        'enableDrivenToWinCategoryMenu',
        'feature_enable_driven_to_win_category_menu'
    ).then((flagValue)=>{
      this.drivenToWinCategoryMenuFFEnabled = flagValue;
    });

    if (this.window.history?.state?.fromOrderPage) {
      this.analyticsService.logGaEvent({
        event: 'basket_created',
        store_number: this.locationDetailsState?.locationDetails?.location.number,
        order_method: this.cartState?.cart?.handoffMode
      });
    }
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  private subscribeToLocationDetailsState(): void {
    const locationDetailsState$ = this.store
      .select(LocationDetailsFeature.selectLocationDetailsState)
      .pipe(filter<LocationDetailsFeatureState>(Boolean));

    this.subscription.add(
      locationDetailsState$.subscribe((state) => {
        this.locationDetailsState = state;
      })
    );
  }

  getOverrideReasonDescription() {
    const location = this.locationDetailsState?.locationDetails?.location;
    const handOffMode = this.cartState?.cart?.handoffMode;
    if (handOffMode === HandoffMode.carryout && location?.unavailableCarryoutOverrideHoursActive) {
      return location.unavailableCarryoutOverrideReasonDescription;
    }

    if (handOffMode === HandoffMode.delivery && location?.unavailableDeliveryOverrideHoursActive) {
      return location.unavailableDeliveryOverrideReasonDescription;
    }
    return "";
  }

  private redirectToPdpIfProductRouteParamFound(storeInfo: StoreInfo | null) {
    const productParam = this.route.snapshot.queryParamMap.get('product');
    if (productParam) {
      const pdpSlugs = buildPdpSlugs(productParam, storeInfo?.categories);
      if (pdpSlugs) {
        const url = `/location/${storeInfo?.storeDetails.slug}/menu/${pdpSlugs}`;
        this.router.navigate([url]);
      }
    }
  }
}
