<div *ngIf="storeInfo$.value" data-testid="location-menu">
  <div
      *ngIf="!isGlobalMenu && storeInfo$.value.storeDetails?.message !== null"
      class="wri-store-message-container"
  >
    <div class="wri-store-message-wrapper sticky-header-adjustment">
      <wri-icon icon="wri-store-message"></wri-icon>
      <span data-testid="store-message"
      >{{ storeInfo$.value.storeDetails?.message }}
      </span>
    </div>
  </div>

  <div *ngIf="overrideReasonDescription" class="override-reason-sticky" data-testid="override-hrs-desc">
    <div class="override-hrs-error-text">
      <wri-icon icon="wri-clock"></wri-icon>
      <span>{{overrideReasonDescription}}</span>
    </div>
  </div>

  <div
    class="header-wrapper"
    [ngClass]="{
      'sticky-header-adjustment':
        !isGlobalMenu && storeInfo$.value.storeDetails?.message === null && overrideReasonDescription === '',
      'sticky-header-adjustment-with-store-message':
        !isGlobalMenu && (storeInfo$.value.storeDetails?.message !== null && overrideReasonDescription === ''),
      'sticky-header-adjustment-with-override-message':
        !isGlobalMenu && (storeInfo$.value.storeDetails?.message === null && overrideReasonDescription !== ''),
      'sticky-header-adjustment-with-two-messages':
        !isGlobalMenu && storeInfo$.value.storeDetails?.message !== null && overrideReasonDescription !== '',
      'sticky-header-adjustment-global-menu':
        isGlobalMenu,
        'sticky-header-adjustment-when-drive-to-win-ff-enabled-for-mobile':
        !isGlobalMenu && drivenToWinCategoryMenuFFEnabled
    }"
  >
    <wri-category-navigation
        [categories]="availableCategories$ | async | sort : 'asc' : 'sortOrder'"
        [selectedCategoryName]="(selectedCategoryInfo$ | async)?.name"
        [selectedCategorySlug]="selectedCategorySlug"
        [locationSlug]="storeInfo$.value?.storeDetails?.slug"
        [drivenToWinCategoryMenuFFEnabled]="drivenToWinCategoryMenuFFEnabled"
        [isGlobalMenu]="isGlobalMenu"
    ></wri-category-navigation>
  </div>
  <div id="page_messaging_above_hero"></div>
  <div>
    <div id="page_messaging_section"></div>
    <div [ngClass]="'wri-wrapper'">
      <wri-breadcrumb [breadcrumbs]="breadcrumbs"></wri-breadcrumb>
      <wri-menu-category
        [isGlobalMenu]="isGlobalMenu"
        [category]="selectedCategoryInfo$ | async"
        [layout]="(selectedCategoryInfo$ | async)?.metadata?.layout"
        [drivenToWinCategoryMenuFFEnabled]="drivenToWinCategoryMenuFFEnabled"
      ></wri-menu-category>
    </div>
  </div>
  <ng-container *ngIf="!isGlobalMenu && storeInfo$.value.storeDetails?.locationDisclaimers?.length > 0">
    <div class="disclaimers-wrapper">
      <div class="disclaimers-inner-wrapper">
        <ng-container
            *ngFor="
            let disclaimer of storeInfo$.value.storeDetails.locationDisclaimers
          "
        >
          <wri-markdown
              class="disclaimer-text"
              [raw]="disclaimer?.message"
          ></wri-markdown>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
