<div *ngIf="!(drivenToWinCategoryMenuFFEnabled && isMobile)"
     class="wri-category-nav-wrapper menu-item-background">
  <div [ngClass]="drivenToWinCategoryMenuFFEnabled ? 'wri-category-title': 'wri-menu-title'">
    {{drivenToWinCategoryMenuFFEnabled && !fromMenuPage ? selectedCategoryName : 'Menu'}}
  </div>
  <div class="hor-scroll-wrap" id="scrollWrapper">
    <div
      id="wri-nav-container"
      class="container-fluid wri-category-nav-container"
      (scroll)="onScroll()"
    >
      <div class="row">
        <ng-container *ngFor="let category of categories">
          <div class="col">
            <div
              class="cat-links"
              (click)="drivenToWinCategoryMenuFFEnabled? navigateToCategoryPage(category?.slug, category?.name): focusOnCat(category?.slug)"
              [id]="'cat-' + category?.slug"
              [attr.data-testid]="'cat-' + category?.slug"
              [ngClass]="{'active' : drivenToWinCategoryMenuFFEnabled && category?.slug === selectedCategorySlug}"
            >
              <a
                [href]="'#' + category?.slug"
                [attr.log-data]="category?.slug"
                wriFocusOutline
                (click)="scrollToSelectedCategory($event, category?.slug)"
              >
                <span class="category-name">{{ category.name }}</span>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>


<div *ngIf="drivenToWinCategoryMenuFFEnabled && isMobile"
     class="wri-category-nav-wrapper-mobile menu-item-background">
  <div class="wri-category-title">
    {{fromMenuPage? "Menu" : selectedCategoryName}}

    <button
      id="category-overflow-menu"
      class="overflow-menu-btn pointer"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      (click)="toggleOverflowMenu()">

      ⋮

    </button>
    <!-- Overflow Menu -->
    <div *ngIf="showOverflowMenu" class="overflow-menu">
      <div
        class="category-menu-item"
        *ngFor="let item of categories"
        (click)="navigateToCategoryPage(item?.slug, item?.name, true)">
        {{ item?.name }}
      </div>
    </div>

  </div>
  <div class="hor-scroll-wrap" id="scrollWrapper-mobile">
    <div
      id="wri-nav-container-mobile"
      class="container-fluid wri-category-nav-container padding0"
      (scroll)="onScroll()">
    </div>
  </div>


</div>
