import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

import {
  MenuCategory,
  StoreInfo
} from '../../../../ecomm/types/store-info.types';
import $ from 'jquery';
import { DOCUMENT } from '../../../../ecomm/providers/document/document.provider';
import { WINDOW } from '../../../../ecomm/providers/window/window.provider';
import { BreadCrumbType, createBreadCrumbClickHandler } from "../../../common";
import { Router } from "@angular/router";

@Component({
  selector: 'wri-location-menu-per-category',
  templateUrl: './location-menu-per-category.component.html',
  styleUrls: ['./location-menu-per-category.component.scss']
})
export class LocationMenuPerCategoryComponent implements OnInit {
  @Input() public isGlobalMenu = false;

  @Input()
  public overrideReasonDescription = '';

  @Input()
  public selectedCategorySlug : string | null;

  @Input()
  public drivenToWinCategoryMenuFFEnabled = false;

  breadcrumbs : BreadCrumbType[]

  public storeInfo$ = new BehaviorSubject<
    StoreInfo | { categories: MenuCategory[] }
  >({ categories: [] });
  links: NodeList | undefined;
  components: NodeList | undefined;

  availableCategories$ = this.storeInfo$.pipe(
    map((info) =>
      info.categories
        .filter((c) => c.availableInSchedule)
        .filter((c) => !(c.metadata?.['display'] === 'hidden'))
    )
  );

  selectedCategoryInfo$ = this.storeInfo$.pipe(
      map((info) =>
          info.categories
              .filter((c) => c.availableInSchedule)
              .filter((c) => !(c.metadata?.['display'] === 'hidden'))
              .find((c) => c.slug === this.selectedCategorySlug)

      )
  );

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(WINDOW) private readonly _window: Window,
    private router: Router
  ) {}

  ngOnInit() {
    let url = `/location/${(this.storeInfo as StoreInfo)?.storeDetails?.slug}/menu`;
    if (this.isGlobalMenu) {
      url = `/menu`;
    }
    this.breadcrumbs = [

      {
        display: 'wri-arrow-back',
        displayType: 'icon',
        displaySeparator: false,
        onClick: createBreadCrumbClickHandler(this.router, url)
      },
      {
        display: 'Menu',
        displayType: 'label',
        displaySeparator: false,
        onClick: createBreadCrumbClickHandler(this.router, url)
      }
    ];
  }

  public get storeInfo(): StoreInfo | { categories: MenuCategory[] } | null {
    return this.storeInfo$.value;
  }

  @Input()
  public set storeInfo(val: StoreInfo | { categories: MenuCategory[] } | null) {
    if (val) {
      this.storeInfo$.next(val);
    }
  }

  @HostListener('window:scroll')
  checkScroll() {
    this.links = this._document.querySelectorAll('.cat-links');
    this.components = this._document.querySelectorAll(
      '.wri-menu-category-wrapper'
    );

    this.components?.forEach((menuComponent) => {
      const element = menuComponent as HTMLElement;
      const top = this._window.scrollY;
      const offset = element.offsetTop - 10;
      const catChildElement = element?.children[0];
      const id = catChildElement.getAttribute('id');

      const topWithHeader = top + 100;

      if (topWithHeader >= offset) {
        const target = this._document.querySelector(`[href='#${id}']`);
        this.activeLink(target, id);
      }
    });
  }

  activeLink(activeElement: Element | null, id: string | null) {
    this.links?.forEach((item) => {
      const linkElement = item as HTMLElement;
      const childElement = linkElement?.children[0];

      childElement.classList.remove('active');
    });
    activeElement?.classList.add('active');
    // Get position of the nav bar and the position of the link with the matching id
    const navPosition = $('#wri-nav-container')?.scrollLeft(),
      elemPosition = $('#cat-' + id)?.offset()?.left;

    // calculations based on wri-category-nav-wrapper padding logic (padding + 3px)
    let offsetLeft = 0;
    if (screen.width < 576) offsetLeft = 19;
    else if (screen.width > 575 && screen.width < 768) offsetLeft = 97;
    else offsetLeft = 43;

    if (navPosition !== undefined && elemPosition !== undefined) {
      $('#wri-nav-container')?.scrollLeft(
        navPosition - offsetLeft + elemPosition
      );
    }
  }
}
