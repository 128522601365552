import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'dev',

  ping: {
    clientId: '5f70cbfc-0d35-4a14-b2b1-b80986d92ebe',
    clientScope: 'openid ecomm_api',
    stsAuthority: 'https://nplogin.wingstop.com/as',
  },
  featureFlags: {
    ...base.featureFlags,
    enableOnetrustPreferencesSyncErrorMessageDisplay: true,
    enableOnetrustUniversalConsent: true,
    enableDrivenToWinCategoryMenu: true,
  },
  ecomm: {
    ...base.ecomm
  },
  ngfeBaseUrl: 'https://dev.sit.ordering.wingstop.com',
};
