<div
    data-testid="menu-category"
    wriInteractionStudio="data-chaincategoryid"
    [interactionStudioId]="category?.id"
>
  <div [ngClass]="'wri-menu-category-wrapper'">
    <span
      *ngIf="!drivenToWinCategoryMenuFFEnabled"
      [ngClass]="'wri-category-name'"
      [id]="category?.slug"
      data-testid="menu-category-name"
    >{{ category?.name }}</span>
    <hr *ngIf="!drivenToWinCategoryMenuFFEnabled"
        [ngClass]="'wri-divider'" aria-hidden="true"/>
    <div class="wri-products-container" [ngClass]="{'wri-products-container-driven-to-win' : drivenToWinCategoryMenuFFEnabled}">
      <ng-container *ngFor="let product of filterOutUnavailableProducts() | sort:'asc':'sortOrder'">
        <div
            wriInteractionStudio="data-chainproductid"
            [interactionStudioId]="product.id"
            class="wri-product-container-item">
          <wri-category-product
              [isGlobalMenu]="isGlobalMenu"
              [product]="product"
              [categorySlug]="category?.slug"
              [categoryName]="category?.name"
              [layout]="layout"
              [drivenToWinCategoryMenuFFEnabled]="drivenToWinCategoryMenuFFEnabled"

          ></wri-category-product>
          <hr class="wri-divider" aria-hidden="true"/>
        </div>
      </ng-container>
    </div>
  </div>
</div>
