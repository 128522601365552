import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filter, Subscription } from 'rxjs';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import {
  MenuItem,
  MenuItemGroup,
  MenuModifier
} from '../../../../ecomm/types/store-info.types';
import { AsynchronousDispatcher } from '../../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { getCalorieRange } from '../../../../ecomm/utils/calorie-range';
import { HighSodiumDisplay } from '../../../../ecomm/types/search-location.types';
import {
  LocationDetailsFeature,
  LocationDetailsFeatureState
} from '../../../../ecomm/store/features/location-details';

@Component({
  selector: 'wri-product-item-group',
  templateUrl: './product-item-group.component.html',
  styleUrls: ['./product-item-group.component.scss']
})
export class ProductItemGroupComponent implements OnInit {
  @Input() isGlobalMenu = false;
  @Input() itemGroup!: MenuItemGroup;
  @Input() categorySlug!: string;
  @Input() categoryName!: string;
  @Input() productSlug!: string;
  @Input() layout: string | undefined;
  @Input() public drivenToWinCategoryMenuFFEnabled = false;


  @ViewChild('commonModal') commonModal!: TemplateRef<HTMLElement>;
  public storeInfoState: StoreInfoFeatureState | null = null;
  public highSodiumDisplay: HighSodiumDisplay | null | undefined = null;
  private subscription = new Subscription();

  constructor(
    private store: Store,
    private router: Router,
    private modalService: NgbModal,
    private asyncDispatcher: AsynchronousDispatcher,
    private analyticsService: AnalyticsService
  ) {}

  /*** Child of Display Group  */
  getProductItemDetailsUrl(item: MenuItem) {
    if (!item?.outOfStock) {
      //close modal
      this.modalService.dismissAll();

      this.asyncDispatcher.dispatch(
        StoreInfoFeature.actions.setState({
          selectedItem: item
        })
      );
      this.analyticsService.logGaEvent({
        event: 'select_item',
        ecommerce: {
          items: [
            {
              item_name: item.name,
              item_id: item.id,
              item_category: this.categoryName,
              quantity: 1,
              price: item.price
            }
          ]
        }
      });

      if (this.isGlobalMenu) {
        this.router.navigate(['order'], {
          queryParams: {
            product: item?.slug
          }
        });
      } else {
        const url = `/location/${this.storeInfoState?.storeInfo?.storeDetails.slug}/menu/${this.categorySlug}/${this.productSlug}/${item.slug}`;
        this.router.navigate([url]);
      }
    }
  }

  openModal() {
    this.modalService.open(this.commonModal, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm'
    });
  }

  ngOnInit() {
    this.subscribeToLocationDetailsState();

    const storeInfo$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));

    this.subscription.add(
      storeInfo$.subscribe((state) => {
        this.storeInfoState = state;
      })
    );
  }

  filterUnavailableItems() {
    return this.itemGroup.items.filter(
      (i) => (i as MenuItem).availableInSchedule
    );
  }

  returnCalorieRange(modifierData: MenuModifier | MenuItem): string | number {
    return getCalorieRange(modifierData);
  }

  private subscribeToLocationDetailsState(): void {
    const locationDetailsState$ = this.store
      .select(LocationDetailsFeature.selectLocationDetailsState)
      .pipe(filter<LocationDetailsFeatureState>(Boolean));

    this.subscription.add(
      locationDetailsState$.subscribe((state) => {
        this.highSodiumDisplay =
          state.locationDetails?.location.highSodiumDisplay;
      })
    );
  }
}
