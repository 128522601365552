import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';

import { EcommAPIConfigProvider } from '../../ecomm/config/ecomm-config.provider';
import { EcommFeatureFlagsProvider } from '../../ecomm/config/ecomm-feature-flags.provider';
import { EcommModule } from '../../ecomm/ecomm.module';
import {
  Config,
  ConfigProvider
} from '../../ecomm/providers/config/config.provider';
import {
  ILegacyAnalyticsService,
  LegacyAnalyticsServiceProvider
} from '../../ecomm/providers/legacy-providers/analytics.service';
import {
  ILegacySeoService,
  LegacySeoServiceProvider
} from '../../ecomm/providers/legacy-providers/seo.service';
import { WriCommonModule } from '../common/common.module';
import { AddOnCardComponent } from './components/add-on-card/add-on-card.component';
import { AddOnItemsComponent } from './components/add-on-items/add-on-items.component';
import { CategoryNavigationComponent } from './components/category-navigation/category-navigation.component';
import { CategoryProductComponent } from './components/category-product/category-product.component';
import { ChooseFlavorModalComponent } from './components/choose-flavor-modal/choose-flavor-modal.component';
import { ChooseFlavorSpiceGroupComponent } from './components/choose-flavor-modal/choose-flavor-spice-group/choose-flavor-spice-group.component';
import { ConnectedLocationMenuComponent } from './components/connected-location-menu/connected-location-menu.component';
import { ConnectedProductDetailsComponent } from './components/connected-product-details/connected-product-details.component';
import { FlavorModElementComponent } from './components/flavor-mod-element/flavor-mod-element.component';
import { FlavorModGroupComponent } from './components/flavor-mod-group/flavor-mod-group.component';
import { FlavorModalCategoryComponent } from './components/flavor-modal-category/flavor-modal-category.component';
import { FlavorModalItemComponent } from './components/flavor-modal-item/flavor-modal-item.component';
import { FlavorModalComponent } from './components/flavor-modal/flavor-modal.component';
import { FlavorQuantityComponent } from './components/flavor-quantity/flavor-quantity.component';
import { FlavorSpiceHeadingComponent } from './components/flavor-spice-heading/flavor-spice-heading.component';
import { GroupFlavorsComponent } from './components/group-flavors/group-flavors.component';
import { ItemDetailsComponent } from './components/item-details/item-details.component';
import { LocationMenuComponent } from './components/location-menu/location-menu.component';
import { MenuCategoryComponent } from './components/menu-category/menu-category.component';
import { MenuRecommendationsComponent } from './components/menu-recommendations/menu-recommendations.component';
import { ModGroupComponent } from './components/mod-group/mod-group.component';
import { ModGroupsComponent } from './components/mod-groups/mod-groups.component';
import { ModifierElementComponent } from './components/modifier-elements/modifier-element.component';
import { ModifierModalComponent } from './components/modifier-modal/modifier-modal.component';
import { ProductItemGroupComponent } from './components/product-item-group/product-item-group.component';
import { ProductItemComponent } from './components/product-item/product-item.component';
import { SpecialRequestsComponent } from './components/special-requests/special-requests.component';
import { UpsellItemsComponent } from './components/upsell-items/upsell-item.component';
import { ConnectedCategoryComponent } from './components/connected-category/connected-category.component';
import {
  LocationMenuPerCategoryComponent
} from "./components/location-menu-per-category/location-menu-per-category.component";
import { CategoryBasedMenuComponent } from "./components/category-based-menu/category-based-menu.component";

export const LocationMenuComponents = [
  CategoryProductComponent,
  ConnectedLocationMenuComponent,
  ConnectedProductDetailsComponent,
  ItemDetailsComponent,
  LocationMenuComponent,
  MenuCategoryComponent,
  ProductItemComponent,
  ProductItemGroupComponent,
  ModGroupsComponent,
  ModGroupComponent,
  ModifierElementComponent,
  FlavorModGroupComponent,
  FlavorModElementComponent,
  FlavorSpiceHeadingComponent,
  FlavorModalItemComponent,
  FlavorModalComponent,
  FlavorModalCategoryComponent,
  ModifierModalComponent,
  GroupFlavorsComponent,
  CategoryNavigationComponent,
  SpecialRequestsComponent,
  FlavorQuantityComponent,
  MenuRecommendationsComponent,
  AddOnItemsComponent,
  AddOnCardComponent,
  ChooseFlavorModalComponent,
  ChooseFlavorSpiceGroupComponent,
  UpsellItemsComponent,
  ConnectedCategoryComponent,
  LocationMenuPerCategoryComponent,
  CategoryBasedMenuComponent
];

@NgModule({
  declarations: [...LocationMenuComponents],
  imports: [
    CommonModule,
    WriCommonModule,
    EcommModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    ToastrModule.forRoot({
      positionClass: 'inline',
      closeButton: true,
      timeOut: 6000,
      maxOpened: 1,
      enableHtml: true,
      autoDismiss: true
    }),
    ToastContainerModule
  ],
  providers: [],
  exports: [...LocationMenuComponents]
})
export class LocationMenuModule {
  public static withConfig(
    config: Config,
    legacy: {
      legacyAnalyticsServiceClass: Type<ILegacyAnalyticsService>;
      legacySeoServiceClass: Type<ILegacySeoService>;
    }
  ): ModuleWithProviders<LocationMenuModule> {
    return {
      ngModule: LocationMenuModule,
      providers: [
        ConfigProvider.get(config),
        EcommAPIConfigProvider.get(config.ecomm),
        EcommFeatureFlagsProvider.get(config.featureFlags),
        LegacyAnalyticsServiceProvider.get(legacy.legacyAnalyticsServiceClass),
        LegacySeoServiceProvider.get(legacy.legacySeoServiceClass)
      ]
    };
  }
}
