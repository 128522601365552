import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { addSpaceToBodyWithStickyFooter } from "../../../../ecomm/utils/dom-manipulations";
import { filter, Subscription, switchMap, tap } from "rxjs";
import { ACTIVATED_ROUTE_SNAPSHOT, LocationMenuPageService, PartialOutageModalComponent } from "../../../common";
import { StoreInfoFeature, StoreInfoFeatureState } from "../../../../ecomm/store/features/store-info";
import { CartFeature, CartFeatureState } from "../../../../ecomm/store/features/cart";
import { LocationDetailsFeature, LocationDetailsFeatureState } from "../../../../ecomm/store/features/location-details";
import { Store } from "@ngrx/store";
import {
  ILegacySeoService,
  LEGACY_SEO_SERVICE,
  structuredDataBreadcrumbList,
  structuredDataRestaurantFromStoreDetails
} from "../../../../ecomm/providers/legacy-providers/seo.service";
import { DOCUMENT } from "../../../../ecomm/providers/document/document.provider";
import { ActivatedRoute, Router } from "@angular/router";
import { StoreInfo } from "../../../../ecomm/types/store-info.types";
import { buildPdpSlugs } from "../../../../ecomm/workflows/store-info/store-info.utilities";
import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState
} from "../../../../ecomm/store/features/regional-configuration";
import { HandoffMode } from "../../../../ecomm/types/selected-handoff-mode.types";
import { FeatureFlagService } from "../../../../ecomm/utils/feature-flag/feature-flag.service";

@Component({
  selector: 'wri-connected-category',
  templateUrl: './connected-category.component.html'
})
export class ConnectedCategoryComponent implements OnInit, OnDestroy {

  public storeInfoState: StoreInfoFeatureState | null = null;
  public cartState: CartFeatureState | null = null;
  public locationDetailsState: LocationDetailsFeatureState | null = null;
  public isLoading = true;
  public selectedCategorySlug: string | null;
  public drivenToWinCategoryMenuFFEnabled = false;
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
      | PartialOutageModalComponent
      | undefined;
  private subscription = new Subscription();
  private storeInfoState$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));
  private cartState$ = this.store
      .select(CartFeature.selectCartState)
      .pipe(filter<CartFeatureState>(Boolean));

  constructor(
      private store: Store,
      @Inject(LEGACY_SEO_SERVICE)
      private seoService: ILegacySeoService,
      @Inject(DOCUMENT)
      private document: Document,
      private route: ActivatedRoute,
      private router: Router,
      private pageService: LocationMenuPageService,
      private featureFlagService: FeatureFlagService
  ) {
  }

  ngOnInit() {
    addSpaceToBodyWithStickyFooter('menu-page');
    this.subscription.add(
        this.route.data
            .pipe(
                filter((data) => data[ACTIVATED_ROUTE_SNAPSHOT]),
                tap(() => (this.isLoading = true)),
                switchMap((data) => {
                  return this.pageService.load$(data[ACTIVATED_ROUTE_SNAPSHOT]);
                })
            )
            .subscribe(() => {
              this.isLoading = false;
            })
    );

    const paramMap = this.route.snapshot.paramMap;
    this.selectedCategorySlug = paramMap.get('categorySlug') ?? undefined;


    this.subscription.add(
        this.storeInfoState$.subscribe((state) => {
          this.storeInfoState = state;
          if (state.storeInfo?.storeDetails) {
            this.seoService.setRobotsNoIndexNoFollow();
            this.seoService.setMetaData({
              title: `${state.storeInfo?.storeDetails?.name}, ${state.storeInfo?.storeDetails?.state} | Order Online | Wingstop`,
              description:
                  `Order Wingstop online at your local ${state.storeInfo?.storeDetails?.name} restaurant! Wings, fries, sides, repeat. ` +
                  "Wing flavors you'll crave even more next time than you did this time. Only at Wingstop."
            });

            this.seoService.addStructuredData([
              structuredDataRestaurantFromStoreDetails(
                  this.document,
                  state.storeInfo.storeDetails
              ),
              structuredDataBreadcrumbList([
                {
                  name: 'Home',
                  url: this.document.location.origin
                },
                {
                  name: `${state.storeInfo.storeDetails.name} Menu`,
                  url: this.document.location.href
                }
              ])
            ]);
          }
        })
    );

    this.subscription.add(
        this.cartState$.subscribe((state) => {
          this.cartState = state;
          if (this.cartState && this.storeInfoState && !this.isLoading) {
            this.redirectToPdpIfProductRouteParamFound(
                this.storeInfoState.storeInfo
            );
          }
        })
    );

    this.subscribeToLocationDetailsState();
    this.subscribeToRegionalConfigState();
    this.featureFlagService.getFeatureFlagValue(
        'enableDrivenToWinCategoryMenu',
        'feature_enable_driven_to_win_category_menu'
    ).then((flagValue)=>{
      this.drivenToWinCategoryMenuFFEnabled = flagValue;
      if (!this.drivenToWinCategoryMenuFFEnabled){
        const url = `/location/${this.storeInfoState.storeInfo.storeDetails.slug}/menu`;
        this.router.navigate([url]);
      }
    });

  }

  getOverrideReasonDescription() {
    const location = this.locationDetailsState?.locationDetails?.location;
    const handOffMode = this.cartState?.cart?.handoffMode;
    if (handOffMode === HandoffMode.carryout && location?.unavailableCarryoutOverrideHoursActive) {
      return location.unavailableCarryoutOverrideReasonDescription;
    }

    if (handOffMode === HandoffMode.delivery && location?.unavailableDeliveryOverrideHoursActive) {
      return location.unavailableDeliveryOverrideReasonDescription;
    }
    return "";
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
        .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
        .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
        regionalConfigState$.subscribe((state) => {
          this.partialOutageModalComponent?.showModal(state);
        })
    );
  }


  private subscribeToLocationDetailsState(): void {
    const locationDetailsState$ = this.store
        .select(LocationDetailsFeature.selectLocationDetailsState)
        .pipe(filter<LocationDetailsFeatureState>(Boolean));

    this.subscription.add(
        locationDetailsState$.subscribe((state) => {
          this.locationDetailsState = state;
        })
    );
  }

  private redirectToPdpIfProductRouteParamFound(storeInfo: StoreInfo | null) {
    const productParam = this.route.snapshot.queryParamMap.get('product');
    if (productParam) {
      const pdpSlugs = buildPdpSlugs(productParam, storeInfo?.categories);
      if (pdpSlugs) {
        const url = `/location/${storeInfo?.storeDetails.slug}/menu/${pdpSlugs}`;
        this.router.navigate([url]);
      }
    }
  }
}
