<div
  data-testid="menu-category"
  wriInteractionStudio="data-chaincategoryid"
  [interactionStudioId]="category?.id"
>
  <a wriFocusOutline tabindex="0" class="wri-category-wpr" data-testid="category-wpr-click"
       (click)="navigateToCategoryPage($event, category?.slug)">
    <div class="category-name" data-testid="category-name">
      {{category?.name}}
    </div>
    <div class="category-image-wpr">
      <img
        height="90px"
        srcset="{{ category?.imageUrl | imageResolution: 'srcset':layout === 'condensed'?'condensed-menu': 'standard-menu': '/assets/images/logo-green-product.png'}}"
        src="{{ category?.imageUrl| imageResolution: 'srcset':layout === 'condensed'?'condensed-menu': 'standard-menu': '/assets/images/logo-green-product.png'}}"
        onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
        alt="{{ category?.name + ' image'  ?? 'placeholder image' }}"
      />
      <wri-icon
        icon="wri-chevron-right"
        class="icon-chevron category-chevron-icon"
      ></wri-icon>
    </div>
  </a>
</div>
