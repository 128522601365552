<!--loading state -->
<wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>
<wri-partial-outage-modal></wri-partial-outage-modal>
<wri-location-menu-per-category
	*ngIf="storeInfoState | shouldShowStoreInfo"
	[storeInfo]="storeInfoState?.storeInfo ?? { categories: [] }"
	[overrideReasonDescription]="getOverrideReasonDescription()"
	[selectedCategorySlug]="selectedCategorySlug"
	[drivenToWinCategoryMenuFFEnabled]="drivenToWinCategoryMenuFFEnabled"
>
</wri-location-menu-per-category>
