<!--loading state -->
<wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>
<wri-partial-outage-modal></wri-partial-outage-modal>
<wri-location-menu
  *ngIf="storeInfoState | shouldShowStoreInfo"
  [storeInfo]="storeInfoState?.storeInfo ?? { categories: [] }"
  [overrideReasonDescription]="getOverrideReasonDescription()"
	[drivenToWinCategoryMenuFFEnabled]="drivenToWinCategoryMenuFFEnabled"
>
</wri-location-menu>
