import {
  Component,
  OnInit,
  AfterContentInit,
  Renderer2,
  Inject, OnDestroy, ViewChild, TemplateRef, ChangeDetectorRef
} from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RoutesRecognized,
} from '@angular/router';
import {
  ModalDismissReasons, NgbModal, NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import {distinctUntilChanged, filter, pairwise, retry} from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { AppStateActions } from './store/app/app-state.actions';
import { SeoService } from '@wingstop/services/seo.service';
import { environment } from '@wingstop/environments/environment';
import { UserIdentityService } from './services/user-identity-service';

import packageJson from '../../package.json';
import gitInfo from '../../git-info.json';
import { set } from "lodash";
import { select, State } from "@ngrx/store";
import { IAppStore } from "@wingstop/store/app-store";
import { JWTWorkflowService } from "./lib/ngfe-app/src/lib/ecomm/workflows/jwt/jwt-workflow.service";
import {BehaviorSubject, catchError, concatMap, from, Observable, of, Subscription, tap, timer} from "rxjs";
import { FeatureFlagService } from "./lib/ngfe-app/src/lib/ecomm/utils/feature-flag/feature-flag.service";
declare var svg4everybody: any;

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterContentInit, OnDestroy {
  private isOneTrustScriptLoaded = false; // Flag to track script loading
  alertState = false;
  closeResult: string;
  isIE11: boolean;
  versionMap: MetaDefinition[] = [
    ...Object.entries(packageJson.dependencies)
      .filter(([k]) => /@wingstop-inc/.test(k))
      .map(([k, v]) => ({ name: k, content: v })),
    { name: 'ngfe-web-sem-ver', content: gitInfo.tag || 'n/a' },
    { name: 'ngfe-web-ver-sha', content: gitInfo.sha || 'n/a' },
  ];

  private oneTrustRetryConfig = {
    count: 5, // Number of retry attempts
    delay: () => {
      if ((window as any).OneTrust === undefined
        || (window as any).OneTrust.syncConsentProfile === undefined)  {
        return timer(500); // Delay in ms before retrying
      }
    }
  };

  public modalRef: NgbModalRef;
  private readonly jwtToken = new BehaviorSubject<string | null>(null);
  private userLoggedIn = false;
  private authCurrentPageUrl: string | null;
  @ViewChild('oneTrustRetryModal') oneTrustRetryModal!: TemplateRef<HTMLElement>;
  private userSubscription: Subscription;
  private isOTUniversalConsentEnabled = false;
  constructor(
    private appActions: AppStateActions,
    private router: Router,
    private seoService: SeoService,
    private renderer2: Renderer2,
    private userIdentityService: UserIdentityService,
    private meta: Meta,
    private appStore: State<IAppStore>,
    @Inject(DOCUMENT) private document: Document,
    private readonly jwtWorkflowService: JWTWorkflowService,
    private modalService: NgbModal,
    private readonly cdRef: ChangeDetectorRef,
    private readonly featureFlagService: FeatureFlagService
  ) {
    this.appActions.setS3SeoMetadata();
    this.seoService.addSeoData();
    this.router.events
      .pipe(
        filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((e: any) => {
        const redirect = e[0].urlAfterRedirects; // previous url
        if (redirect && redirect.match(/^(?!\/account).*$/)) {
          this.appActions.setRedirect(redirect);
        }
      });
    this.meta.addTags(this.versionMap);

    this.appStore.subscribe((value) => {
      const { storeInfo } = value?.storeInfo || {};
      set(
        window,
        ['ws', 'location', 'menu'],
        storeInfo?.categories
      );
    });
  }

  private async initializeOneTrust(email: string, showError: boolean) {
    const fromSignUp  = this.authCurrentPageUrl === "/account/signup"
    const jwtToken = await this.jwtWorkflowService.getJWTToken(email, showError, fromSignUp);
    this.jwtToken?.next(jwtToken?.token);

    if (this.jwtToken?.value) {
      (window as any).OneTrust = {
        ...((window as any).OneTrust || {}),
        dataSubjectParams: {
          id: email,
          identifierType: "Email",
          isAnonymous: false,
          token: this.jwtToken?.value
        }
      };
    }
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.isIE11 =
      !!(window as any).MSInputMethodContext &&
      !!(document as any).documentMode;

    (window as any).addEventListener(
      'wri-open-one-trust-preferences-clicked',
      this.handleOneTrustCookiesClick.bind(this)
    );

    // if the current and next router are the same...
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // we should not reuse the loaded component (stale data) -- refresh
    };

    // subscribe to changes in the router
    this.router.events.subscribe((evt) => {
      if (evt && evt instanceof NavigationStart && evt.url) {
        this.appActions.setIsDigitalMenu(evt.url.includes('digital-menu'));
      }
      // if there was a successful page transition
      if (evt instanceof NavigationEnd) {
        // tell the router its last link wasn't loaded
        this.router.navigated = false;

        // Set focus to navbar to put Skip to Main button within one tab key press
        const nav = document.getElementById('appNavbar');
        if (nav) {
          nav.focus();
        }
        window.scrollTo(0, 0);

        // }
        // scroll to the top
        return setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0);
      }
    });

    this.userIdentityService.setFirstVisitTimestamp();
    this.appActions.setIsUserFirstThreeMonths();

    this.appStore.subscribe((value) => {
      const { currentPageUrl } = value?.auth || {};
      this.authCurrentPageUrl = currentPageUrl;
    });

    this.userSubscription = this.appStore
      .pipe(select(state => state.customer), distinctUntilChanged((prev, curr) => prev === curr))
      .subscribe(async customerState => {
        const showOTErrorMessage = await this.featureFlagService.getFeatureFlagValue(
          'enableOnetrustPreferencesSyncErrorMessageDisplay',
          'feature_enable_onetrust_preferences_sync_error_message_display'
        );
        this.isOTUniversalConsentEnabled = await this.featureFlagService.getFeatureFlagValue(
          'enableOnetrustUniversalConsent',
          'feature_enable_onetrust_universal_consent'
        );

        if (customerState?.customer?.email && this.isOTUniversalConsentEnabled) {
          this.userLoggedIn = true;
          from(this.initializeOneTrust(customerState?.customer?.email, showOTErrorMessage))
            .pipe(
              tap(() => {
                console.log('OneTrust script load starting');
              }),
              concatMap(() => this.loadOneTrustFromGtm()), // Load OneTrust script
              tap(() => this.addScriptTags()),
              tap(() => this.syncProfile(customerState?.customer?.email)),
              retry({
                count: this.oneTrustRetryConfig.count,
                delay: this.oneTrustRetryConfig.delay,
              }),
              catchError((error) => {
                console.error('OneTrust:', error);
                return of(null); // Return a fallback Observable to complete the chain
              })
            )
            .subscribe();
        } else {
          console.log('OneTrust script load starting');
          this.addScriptTags();
          this.loadOneTrustFromGtm().pipe(
            catchError((error) => {
              console.error('OneTrust:', error);
              return of(null); // Return a fallback Observable to complete the chain
            })
          )
          .subscribe();
        }
        console.log('OneTrust script load complete');
      }
    );
  }

  syncProfile(email: string | null): void {
    if ((window as any).OneTrust) {
      console.log('Syncing user consent with OneTrust starting');
      const oneTrust = (window as any).OneTrust;
      oneTrust.syncConsentProfile(email, this.jwtToken?.value,false);
      console.log('Syncing user consent with OneTrust complete');
    }
  }

  ngAfterContentInit(): void {
    svg4everybody();
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    (window as any).removeEventListener('wri-open-one-trust-preferences-clicked', this.handleOneTrustCookiesClick);
  }

  private setGO() {
    if (environment.googleOptimizeId) {
      const id = environment.googleOptimizeId;
      const s = this.document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://www.googleoptimize.com/optimize.js?id=${id}`;
      const head = this.document.getElementsByTagName('head')[0];
      head.appendChild(s);
    }
  }

  private setGOFlickerSnippet() {
    const head = this.document.getElementsByTagName('head')[0];
    const styleTag = this.document.createElement('style');
    const s = this.document.createElement('script');
    // create style tag
    styleTag.innerHTML = `.async-hide { opacity: 0 !important}`;
    // create script tag
    s.type = 'text/javascript';
    s.innerHTML = `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
    h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
    (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
    })(window,document.documentElement,'async-hide','dataLayer',100,
    {'CONTAINER_ID':true});`;
    // append to DOM
    head.appendChild(styleTag);
    head.appendChild(s);
    this.setGO();
  }

  private insertScriptFromEnvironmentVariable(property: string) {
    const body = this.document.getElementsByTagName('body')[0];
    const s = this.document.createElement('script');
    s.src =
      typeof environment[property] === 'string'
        ? environment[property]
        : environment[property]?.url;
    body.appendChild(s);
  }

  addScriptTags(): void {
    if (this.isIE11) {
      return;
    }

    if (environment.grecaptcha.siteKey) {
      const reCaptchaScript = this.renderer2.createElement(
        'script'
      ) as HTMLScriptElement;
      reCaptchaScript.type = 'text/javascript';
      reCaptchaScript.src = `https://www.google.com/recaptcha/enterprise.js?render=${environment.grecaptcha.siteKey}`;
      reCaptchaScript.async = true;

      this.renderer2.appendChild(this.document.head, reCaptchaScript);
    }
    this.setGOFlickerSnippet();
    this.insertScriptFromEnvironmentVariable('epsilonRTI');
    this.insertScriptFromEnvironmentVariable('smfc');
  }

  openOneTrustRetryModal() {
    this.cdRef.detectChanges();
    this.modalService.open(this.oneTrustRetryModal, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm',
      backdrop: 'static',
      keyboard: false
    });
  }

  handleOneTrustCookiesClick(event: Event) {
    event.preventDefault();
    if (!this.jwtToken?.value && this.userLoggedIn && this.isOTUniversalConsentEnabled) {
      this.openOneTrustRetryModal();
    } else {
      (window as any).OneTrust.ToggleInfoDisplay();
    }
  }

  reloadApp($event: MouseEvent) {
    $event.preventDefault();
    window.location.reload();
  }

  openOneTrustPreferencesManually(event: Event) {
    event.preventDefault();
    (window as any).OneTrust.ToggleInfoDisplay();
  }

  loadOneTrustFromGtm(): Observable<void> {
    return new Observable<void>((observer) => {
      if (!this.isOneTrustScriptLoaded) {
        try {
          const gtagScript = this.renderer2.createElement('script');
          gtagScript.type = 'text/javascript';
          gtagScript.innerHTML = environment.gtagScript;

          gtagScript.onload = () => {
            observer.next(); // Emit success
            observer.complete(); // Complete the Observable
          };

          gtagScript.onerror = (error) => {
            console.error('Error loading GTM script', error);
            observer.error(error); // Emit error
          };

          this.renderer2.appendChild(document.head, gtagScript);
          this.isOneTrustScriptLoaded = true; // Mark as loaded
        } catch (error) {
          observer.error(error); // Emit error
        }
      }
      else{
        observer.next(); // Emit success
        observer.complete(); // Complete the Observable
      }
    });
  }
}
