import { Component, Input } from '@angular/core';

import { BreadCrumbType } from './breadcrumb.type';

@Component({
  selector: 'wri-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  defaultSeparator = '/';
  @Input() breadcrumbs: Array<BreadCrumbType> | undefined;
}
