import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PathParams } from '../../ecomm/constants/params';
import { CAN_USE_CUSTOM_SEO } from '../../ecomm/store/middleware/static-seo-metadata-effects';
import { cacheLoadedGuard } from '../common/guards/cache-loaded.guard';
import {
  ACTIVATED_ROUTE_SNAPSHOT,
  ActivatedRouteSnapshotResolver
} from '../common/resolvers/activated-route-snapshot.resolver';
import {
  ACTIVE_OFFER,
  ActiveOfferResolver
} from '../common/resolvers/active-offer.resolver';
import { ConnectedLocationMenuComponent } from './components/connected-location-menu/connected-location-menu.component';
import { ConnectedProductDetailsComponent } from './components/connected-product-details/connected-product-details.component';
import { LocationMenuModule } from './location-menu.module';
import { offlineGuard } from '../common';
import { combineGuard } from '../common/guards/combine.guard';
import { ConnectedCategoryComponent } from "./components/connected-category/connected-category.component";

export const locationMenuRoutes: Routes = [
  {
    path: `:${PathParams.locationSlug}/menu/:${PathParams.categorySlug}`,
    component: ConnectedCategoryComponent,
    data: {
      reuse: true,
      [CAN_USE_CUSTOM_SEO]: true
    },
    canActivate: [combineGuard(cacheLoadedGuard, offlineGuard)],
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [ACTIVE_OFFER]: ActiveOfferResolver
    }
  },
  {
    path: `:${PathParams.locationSlug}/menu`,
    component: ConnectedLocationMenuComponent,
    data: {
      reuse: true,
      [CAN_USE_CUSTOM_SEO]: true
    },
    canActivate: [combineGuard(cacheLoadedGuard, offlineGuard)],
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [ACTIVE_OFFER]: ActiveOfferResolver
    }
  },
  {
    path: `:${PathParams.locationSlug}/menu/:${PathParams.categorySlug}/:${PathParams.productSlug}`,
    component: ConnectedProductDetailsComponent,
    data: {
      reuse: true,
      [CAN_USE_CUSTOM_SEO]: true
    },
    canActivate: [combineGuard(cacheLoadedGuard, offlineGuard)],
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [ACTIVE_OFFER]: ActiveOfferResolver
    }
  },
  {
    path: `:${PathParams.locationSlug}/menu/:${PathParams.categorySlug}/:${PathParams.productSlug}/:${PathParams.itemSlug}`,
    component: ConnectedProductDetailsComponent,
    data: {
      reuse: true,
      [CAN_USE_CUSTOM_SEO]: true
    },
    canActivate: [combineGuard(cacheLoadedGuard, offlineGuard)],
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [ACTIVE_OFFER]: ActiveOfferResolver
    }
  },
  {
    path: `:${PathParams.locationSlug}/in-store`,
    redirectTo: `:${PathParams.locationSlug}/menu`,
    pathMatch: 'full'
  },
  {
    path: `:${PathParams.locationSlug}/digital-menu`,
    redirectTo: `:${PathParams.locationSlug}/menu`,
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: '/order',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [LocationMenuModule, RouterModule.forChild(locationMenuRoutes)]
})
export class LocationMenuRoutingModule { }
