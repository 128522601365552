import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Observable, Subscription, of } from 'rxjs';

import { OrderSubmitRequestPayment } from '../../../../../ecomm/types/order.types';
import {
  GiftCardDetails,
  VaultedGiftCards
} from '../../../../../ecomm/types/payment.types';
import { StoreInfo } from '../../../../../ecomm/types/store-info.types';
import { NotificationService } from '../../../../../ecomm/utils/notification/notification.service';
import { PaymentWorkflowService } from '../../../../../ecomm/workflows/payment/payment-workflow.service';
import { CheckboxComponent } from '../../../../common';
import {
  GiftCardFormData,
  GiftCardPaymentMethodStatusEvent,
  PaymentInfo,
  PaymentMethodLike
} from '../payment-method/payment-method.types';

type AddedGiftCardDetails =
  | (GiftCardDetails & {
    id: string;
    type: 'anonymous';
    cardNumber: string;
    securityCode: string;
    requestedAmount: number;
  })
  | (VaultedGiftCards & {
    id: string;
    type: 'vaulted';
    requestedAmount: number;
  });

@Component({
  selector: 'wri-gift-card-payment-method',
  templateUrl: './gift-card-payment-method.component.html',
  styleUrls: ['./gift-card-payment-method.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => GiftCardPaymentMethodComponent)
    }
  ]
})
export class GiftCardPaymentMethodComponent
  extends CheckboxComponent
  implements OnInit, OnDestroy, PaymentMethodLike, OnChanges {
  private _totalPrice = 0;
  @Input()
  set totalPrice(val: number) {
    this._totalPrice = val || this._totalPrice || 0;
    this.addedGiftCards = this.sortAddedCardDetails(this.addedGiftCards ?? []);
    this.updateStatus();
  }
  get totalPrice(): number {
    return this._totalPrice;
  }

  @Input()
  initialValues: Partial<GiftCardFormData> = {};

  @Input()
  public set customerVaultedGiftCards(input: VaultedGiftCards[] | null) {
    this.removeAllVaultedCardsFromForm();
    this.addAllVaultedGiftCardsToForm(input);
    this.addedGiftCards = this.setAddedCards(input);
    this.updateStatus();
  }

  @Input()
  public storeInfoData: StoreInfo | null = null;

  private _userLoggedIn = false;

  @Input() set userLoggedIn(input: boolean) {
    this._userLoggedIn = input;
    this.updateForms();
  }

  get userLoggedIn(): boolean {
    return this._userLoggedIn;
  }

  @Input()
  paymentType = '';

  @Input()
  isGiftCardPaymentSupported = false;

  @Input()
  isVaultedGiftCardPaymentSupported = false;

  @Input()
  vaultedGiftCardView = false;

  @Input()
  vaultedGiftCardVault = false;

  @Input()
  vaultedGiftCardPay = false;

  @Output()
  statusChanged = new EventEmitter<GiftCardPaymentMethodStatusEvent>();

  @Output()
  addGiftCardFormValid = new EventEmitter<boolean>(false);

  private subscription = new Subscription();
  public giftCardForm: UntypedFormGroup = new UntypedFormGroup({});
  public addedGiftCardForm: UntypedFormGroup = new UntypedFormGroup({});
  public addedGiftCards: AddedGiftCardDetails[] = [];
  public addedGiftCardsVaulted: AddedGiftCardDetails[] = [];
  public addedGiftCardsAnonymous: AddedGiftCardDetails[] = [];

  public isLoading = false;

  constructor(
    private fb: UntypedFormBuilder,
    private paymentWorkflowService: PaymentWorkflowService,
    private notificationService: NotificationService
  ) {
    super();
  }

  public get isVaultedGiftCardEnabled() {
    return this.userLoggedIn && this.vaultedGiftCardVault;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['vaultedGiftCardVault']) {
      this.updateForms();
    }
  }

  ngOnInit(): void {
    this.addedGiftCardForm = this.fb.group({});

    this.subscription.add(
      this.addedGiftCardForm.valueChanges.subscribe(() => {
        this.addedGiftCards = this.sortAddedCardDetails(this.addedGiftCards);
        this.updateStatus();
      })
    );
    this.updateStatus();
  }

  private updateForms(): void {
    // Only initialize or update the form if `userLoggedIn` or other relevant inputs are set
    this.giftCardForm = this.fb.group({
      cardNumber: [this.initialValues.cardNumber ?? '', Validators.required],
      securityCode: [
        this.initialValues.securityCode ?? '',
        Validators.required
      ],
      vault: [this.isVaultedGiftCardEnabled, Validators.required]
    });
  }

  ngOnDestroy(): void {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  getPaymentInfo(): Observable<PaymentInfo> {
    const payments: OrderSubmitRequestPayment[] = this.addedGiftCards
      .filter((c) => c.requestedAmount > 0)
      .map(this.toOrderSubmitRequestPayment.bind(this));
    return of({
      billingMethod: 'onlinePay',
      payments
    });
  }

  turnAllAppliedGiftCardsOff(): void {
    this.addedGiftCardForm.reset();
  }

  getGiftCardFormatter() {
    return (value: string) => value.replace(/[\s-\D]+/g, '');
  }

  public async add() {
    this.isLoading = true;

    const formData: GiftCardFormData = this.giftCardForm.value;
    if (!formData.cardNumber || !formData.securityCode) {
      this.notificationService.showError(
        'Please fill out form to add gift card.'
      );
      this.isLoading = false;
      return;
    }

    if (this.checkGiftCardExists(formData.cardNumber)) {
      this.notificationService.showError(
        'The gift card you entered has already been added.'
      );
      this.isLoading = false;
      return;
    }

    const svs = this.storeInfoData?.storeDetails.svsMerchantNumber;
    if (!svs) {
      this.notificationService.showError(
        'Could not determine SVS merchant number'
      );
      this.isLoading = false;
      return;
    }

    const details = await this.paymentWorkflowService
      .addGiftCard({
        ...formData,
        svsMerchantNumber: svs,
        currency: 'USD'
      })
      .catch(() => {
        this.notificationService.showError(
          'We encountered an unexpected error adding your gift card. Please try again.'
        );
        return null;
      });
    if (!details) {
      this.isLoading = false;
      return;
    }

    if (this.checkGiftCardExistsFromDetails(details)) {
      this.notificationService.showError(
        'The gift card you entered has already been added.'
      );
      this.isLoading = false;
      return;
    }

    this.addGiftCardToForm(details.vaultedAccountId ?? formData.cardNumber);
    this.addGiftCardToList(formData.cardNumber, formData.securityCode, details);
    this.addGiftCardFormValid.emit(true);
    this.updateStatus();
    this.giftCardForm.reset({
      cardNumber: '',
      securityCode: '',
      vault: this.isVaultedGiftCardEnabled
    });
    this.writeValue(false);
    this.isLoading = false;
  }

  public remove(type: AddedGiftCardDetails['type'], id: string) {
    this.isLoading = true;
    this.removeGiftCardFromList(type, id);
    this.removeGiftCardFromForm(id);
    this.updateStatus();
    if (type === 'vaulted') {
      this.paymentWorkflowService.deleteVaultedCard(id, false);
    }
    this.isLoading = false;
  }

  public toggleAddedCardStatus(id: string, checked: boolean) {
    this.addedGiftCardForm.patchValue({ [id]: checked });
  }

  public toggleAddGiftCardForm(checked: boolean) {
    super.writeValue(checked)
    const formData: GiftCardFormData = this.giftCardForm.value;
    if (checked) {
      if (!formData.cardNumber || !formData.securityCode) {
        this.addGiftCardFormValid.next(false);
        return;
      }
    }
    this.addGiftCardFormValid.next(true);
  }

  private checkGiftCardExists(cardNumber: string) {
    return this.addedGiftCards.some(
      (item) => item.type === 'anonymous' && item.cardNumber === cardNumber
    );
  }

  private checkGiftCardExistsFromDetails(details: GiftCardDetails) {
    return this.addedGiftCards.some(
      (item) => item.type === 'vaulted' && item.id === details.vaultedAccountId
    );
  }

  private toOrderSubmitRequestPayment(
    input: AddedGiftCardDetails
  ): OrderSubmitRequestPayment {
    switch (input.type) {
      case 'anonymous': {
        return {
          type: 'giftCard',
          cardNumber: input.cardNumber,
          securityCode: input.securityCode,
          requestedAmount: Number(input.requestedAmount.toFixed(2))
        };
      }
      case 'vaulted': {
        return {
          type: 'vaultedGiftCard',
          requestedAmount: Number(input.requestedAmount.toFixed(2)),
          vaultedGiftCardId: input.vaultedAccountId
        };
      }
    }
  }

  private toAnonymousAddedGiftCardDetails(
    cardNumber: string,
    securityCode: string,
    details: GiftCardDetails
  ): AddedGiftCardDetails {
    return {
      ...details,
      id: cardNumber,
      type: 'anonymous',
      requestedAmount: 0,
      cardNumber,
      securityCode,
      vaultedAccountId: undefined
    };
  }

  private toValutedAddedGiftCardDetails(
    details: GiftCardDetails
  ): AddedGiftCardDetails {
    return {
      ...details,
      id: details.vaultedAccountId ?? '',
      type: 'vaulted',
      requestedAmount: 0,
      vaultedAccountId: details.vaultedAccountId ?? ''
    };
  }

  private updateStatus() {
    this.addedGiftCardsVaulted = this.addedGiftCards.filter((card) => card.type === 'vaulted');
    this.addedGiftCardsAnonymous = this.addedGiftCards.filter((card) => card.type === 'anonymous');
    this.statusChanged.emit({
      priceAfterGiftCards:
        this._totalPrice -
        this.addedGiftCards.reduce((sum, ac) => sum + ac.requestedAmount, 0),
      giftCardCount: this.addedGiftCards.length,
      appliedGiftCardCount: this.addedGiftCards.filter(
        (ac) => ac.requestedAmount > 0
      ).length,
      appliedGiftCardCountWithZeroBalance: this.addedGiftCards.filter(
        (ac) =>
          this.addedGiftCardForm.value[ac.id] &&
          ac.balance === 0 &&
          ac.requestedAmount === 0
      ).length
    });
  }

  private addGiftCardToForm(id: string, val = true) {
    if (!this.vaultedGiftCardPay) {
      val = false;
    }
    this.addedGiftCardForm.addControl(id, new FormControl(val));
  }

  private addAllVaultedGiftCardsToForm(
    vaultedCards: VaultedGiftCards[] | null
  ) {
    if (vaultedCards) {
      vaultedCards.map((vc) =>
        this.addGiftCardToForm(vc.vaultedAccountId, false)
      );
    }
  }

  private addGiftCardToList(
    cardNumber: string,
    securityCode: string,
    details: GiftCardDetails
  ) {
    this.addedGiftCards = this.sortAddedCardDetails([
      ...this.addedGiftCards,
      details.vaultedAccountId
        ? this.toValutedAddedGiftCardDetails(details)
        : this.toAnonymousAddedGiftCardDetails(
          cardNumber,
          securityCode,
          details
        )
    ]);
  }

  private removeGiftCardFromForm(id: string) {
    this.addedGiftCardForm.removeControl(id);
  }

  private removeGiftCardFromList(
    type: AddedGiftCardDetails['type'],
    id: string
  ) {
    this.addedGiftCards = this.sortAddedCardDetails(
      this.addedGiftCards.filter((ac) => !(ac.type === type && ac.id === id))
    );
  }

  private removeAllVaultedCardsFromForm() {
    this.addedGiftCards
      .filter((c) => c.type !== 'anonymous')
      .forEach((c) => this.addedGiftCardForm.removeControl(c.id));
  }

  private setAddedCards(
    vaultedCards: VaultedGiftCards[] | null
  ): AddedGiftCardDetails[] {
    if (vaultedCards) {
      return this.sortAddedCardDetails([
        ...vaultedCards.map((vc) =>
          this.toValutedAddedGiftCardDetails({
            ...vc,
            currency: 'USD'
          })
        ),
        ...this.addedGiftCards.filter((ac) => ac.type === 'anonymous')
      ]);
    }
    return this.sortAddedCardDetails([
      ...this.addedGiftCards.filter((ac) => ac.type === 'anonymous')
    ]);
  }

  private sortAddedCardDetails(
    input: AddedGiftCardDetails[]
  ): AddedGiftCardDetails[] {
    let amountLeftToAssign = this.totalPrice;
    return input
      .sort((ac1, ac2) => {
        return ac1.balance - ac2.balance;
      })
      .reduce((acc, ac) => {
        const id = ac.id;
        if (!this.isVaultedGiftCardPaymentSupported && ac.type === 'vaulted') {
          return [...acc, { ...ac, requestedAmount: 0 }];
        }
        if (!this.isGiftCardPaymentSupported && ac.type === 'anonymous') {
          return [...acc, { ...ac, requestedAmount: 0 }];
        }
        const isSelected: boolean = this.addedGiftCardForm.controls[id]?.value;
        const amountToUse = isSelected
          ? Math.min(ac.balance, amountLeftToAssign)
          : 0;
        amountLeftToAssign = Math.max(0, amountLeftToAssign - amountToUse);
        return [...acc, { ...ac, requestedAmount: amountToUse }];
      }, [] as AddedGiftCardDetails[]);
  }
}
