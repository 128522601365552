import { Component, HostBinding, Input } from '@angular/core';

import { MenuProduct } from '../../../../ecomm/types/store-info.types';

@Component({
  selector: 'wri-category-product',
  templateUrl: './category-product.component.html',
  styleUrls: ['./category-product.component.scss']
})
export class CategoryProductComponent {
  @HostBinding('className') componentClass: string;
  @Input() product!: MenuProduct;
  @Input() categorySlug!: string;
  @Input() categoryName!: string;
  @Input() layout: string | undefined;
  @Input() isGlobalMenu = false;
  @Input() public drivenToWinCategoryMenuFFEnabled = false;

  constructor() {
    this.componentClass = 'menu-category-product-comp';
  }
}
