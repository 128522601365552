import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, Subscription } from 'rxjs';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import {
  MenuItem,
  MenuModifier
} from '../../../../ecomm/types/store-info.types';
import { AsynchronousDispatcher } from '../../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { getCalorieRange } from '../../../../ecomm/utils/calorie-range';
import { HighSodiumDisplay } from '../../../../ecomm/types/search-location.types';
import {
  LocationDetailsFeature,
  LocationDetailsFeatureState
} from '../../../../ecomm/store/features/location-details';

@Component({
  selector: 'wri-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent implements OnInit, OnDestroy {
  @Input() isGlobalMenu = false;
  @Input() item!: MenuItem;
  @Input() categorySlug!: string;
  @Input() productSlug!: string;
  @Input() layout: string | undefined;
  @Input() public drivenToWinCategoryMenuFFEnabled = false;

  public storeInfoFeatureState: StoreInfoFeatureState | null = null;
  public highSodiumDisplay: HighSodiumDisplay | null | undefined = null;

  private subscription = new Subscription();

  constructor(
    private store: Store,
    private router: Router,
    private asyncDispatcher: AsynchronousDispatcher,
    private analyticsService: AnalyticsService
  ) {}

  /***  Display Group  */
  getProductItemDetailsUrl() {
    if (!this.item?.outOfStock) {
      //saving item state
      this.asyncDispatcher.dispatch(
        StoreInfoFeature.actions.setState({
          selectedItem: this.item
        })
      );

      this.analyticsService.logGaEvent({
        event: 'select_item',
        ecommerce: {
          items: [
            {
              item_name: this.item.name,
              item_id: this.item.id,
              item_category: this.categorySlug,
              quantity: 1,
              price: this.item.price
            }
          ]
        }
      });

      if (this.isGlobalMenu) {
        this.router.navigate(['order'], {
          queryParams: {
            product: this.item?.slug
          }
        });
      } else {
        const url = `/location/${this.storeInfoFeatureState?.storeInfo?.storeDetails.slug}/menu/${this.categorySlug}/${this.productSlug}`;
        this.router.navigate([url]);
      }
    }
  }

  hasPrice(price: number): boolean {
    return parseFloat(price.toString()) !== 0;
  }

  returnCalorieRange(modifierData: MenuModifier | MenuItem): string | number {
    return getCalorieRange(modifierData);
  }

  ngOnInit() {
    this.subscribeToLocationDetailsState();

    const storeInfo$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));

    this.subscription.add(
      storeInfo$.subscribe((state) => {
        this.storeInfoFeatureState = state;
      })
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private subscribeToLocationDetailsState(): void {
    const locationDetailsState$ = this.store
      .select(LocationDetailsFeature.selectLocationDetailsState)
      .pipe(filter<LocationDetailsFeatureState>(Boolean));

    this.subscription.add(
      locationDetailsState$.subscribe((state) => {
        this.highSodiumDisplay =
          state.locationDetails?.location.highSodiumDisplay;
      })
    );
  }
}
