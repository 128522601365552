import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PathParams } from '../../ecomm/constants/params';
import { CAN_USE_CUSTOM_SEO } from '../../ecomm/store/middleware/static-seo-metadata-effects';
import { cacheLoadedGuard } from '../common/guards/cache-loaded.guard';
import { combineGuard } from '../common/guards/combine.guard';
import { featureFlagRouteGuard } from '../common/guards/feature-flag.guard';
import { globalMenuGuard } from '../common/guards/global-menu.guard';
import { ConnectedGlobalMenuComponent } from './components/connected-global-menu/connected-global-menu.component';
import { ConnectedGlobalProductDetailsComponent } from './components/connected-global-product-details/connected-global-product-details.component';
import { GlobalMenuModule } from './global-menu.module';
import {
  ACTIVATED_ROUTE_SNAPSHOT,
  ActivatedRouteSnapshotResolver,
  ACTIVE_OFFER,
  ActiveOfferResolver,
  offlineGuard
} from '../common';
import {
  ConnectedGlobalCategoryComponent
} from "./components/connected-global-category/connected-global-category.component";

const globalMenuRoutes: Routes = [
  {
    path: '',
    component: ConnectedGlobalMenuComponent,
    canActivate: [combineGuard(cacheLoadedGuard, globalMenuGuard, featureFlagRouteGuard('enableGlobalMenu'), offlineGuard)],
    resolve: {
      [ACTIVE_OFFER]: ActiveOfferResolver
    },
    data: {
      reuse: true
    }
  },
  {
    path: `:${PathParams.categorySlug}`,
    component: ConnectedGlobalCategoryComponent,
    canActivate: [combineGuard(cacheLoadedGuard, globalMenuGuard, featureFlagRouteGuard('enableGlobalMenu'), offlineGuard)],
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [ACTIVE_OFFER]: ActiveOfferResolver
    },
    data: {
      reuse: true
    }
  },
  {
    path: `:${PathParams.categorySlug}/:${PathParams.productSlug}`,
    component: ConnectedGlobalProductDetailsComponent,
    canActivate: [combineGuard(cacheLoadedGuard, globalMenuGuard, featureFlagRouteGuard('enableGlobalMenu'), offlineGuard)],
    resolve: {
      [ACTIVE_OFFER]: ActiveOfferResolver
    },
    data: {
      reuse: true,
      [CAN_USE_CUSTOM_SEO]: true
    }
  },
  {
    path: `:${PathParams.categorySlug}/:${PathParams.productSlug}/:${PathParams.itemSlug}`,
    component: ConnectedGlobalProductDetailsComponent,
    canActivate: [combineGuard(cacheLoadedGuard, globalMenuGuard, featureFlagRouteGuard('enableGlobalMenu'), offlineGuard)],
    resolve: {
      [ACTIVE_OFFER]: ActiveOfferResolver
    },
    data: {
      reuse: true,
      [CAN_USE_CUSTOM_SEO]: true
    }
  }
];

@NgModule({
  imports: [GlobalMenuModule, RouterModule.forChild(globalMenuRoutes)]
})
export class GlobalMenuRoutingModule { }
