<div
    [ngClass]="'wri-category-product-container'"
    data-testid="category-product"
>
  <div [ngClass]="'wri-product-container'" data-testid="category-product">
    <ng-template
        [ngIf]="product.item !== null && product.item?.availableInSchedule"
    >
      <wri-product-item
          [isGlobalMenu]="isGlobalMenu"
          [item]="product.item"
          [categorySlug]="categorySlug"
          [productSlug]="product.slug"
          [layout]="layout"
          [drivenToWinCategoryMenuFFEnabled]="drivenToWinCategoryMenuFFEnabled"
      >
      </wri-product-item>
    </ng-template>
    <ng-template
        [ngIf]="
        product.itemGroup !== null && product.itemGroup?.availableInSchedule
      "
    >
      <wri-product-item-group
          [isGlobalMenu]="isGlobalMenu"
          [itemGroup]="product.itemGroup"
          [categorySlug]="categorySlug"
          [categoryName]="categoryName"
          [productSlug]="product.slug"
          [layout]="layout"
          [drivenToWinCategoryMenuFFEnabled]="drivenToWinCategoryMenuFFEnabled"
      >
      </wri-product-item-group>
    </ng-template>
  </div>
</div>
