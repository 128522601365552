import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '../../../../ecomm/providers/document/document.provider';
import {
  ILegacySeoService,
  LEGACY_SEO_SERVICE,
  structuredDataBreadcrumbList
} from '../../../../ecomm/providers/legacy-providers/seo.service';

import { StoreInfoFeatureState } from '../../../../ecomm/store/features/store-info';
import { StoreInfo } from '../../../../ecomm/types/store-info.types';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import { NotificationService } from '../../../../ecomm/utils/notification/notification.service';
import { UNKNOWN_ERROR } from '../../../../ecomm/utils/throw-error';
import { Store } from '@ngrx/store';
import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState
} from '../../../../ecomm/store/features/regional-configuration';
import { filter, Subscription } from 'rxjs';
import { PartialOutageModalComponent } from '../../../common';
import { FeatureFlagService } from "../../../../ecomm/utils/feature-flag/feature-flag.service";

@Component({
  selector: 'wri-connected-global-menu',
  template: `
    <wri-feature-flags [with]="['enableGlobalMenu']">
      <wri-page-loading-indicator
        *ngIf="isLoading"
      ></wri-page-loading-indicator>
      <wri-partial-outage-modal></wri-partial-outage-modal>

      <wri-location-menu
        *ngIf="storeInfo"
        [storeInfo]="storeInfo"
        [isGlobalMenu]="true"
        [drivenToWinCategoryMenuFFEnabled]="drivenToWinCategoryMenuFFEnabled"
      >
      </wri-location-menu>
    </wri-feature-flags>
  `
})
export class ConnectedGlobalMenuComponent implements OnInit, OnDestroy {
  isLoading = false;
  public storeInfoState: StoreInfoFeatureState | null = null;
  public storeInfo: StoreInfo | null = null;
  public drivenToWinCategoryMenuFFEnabled = false;
  private subscription = new Subscription();
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  constructor(
    private storeInfoWorkflowService: StoreInfoWorkflowService,
    private redirectService: RedirectService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LEGACY_SEO_SERVICE) private seoService: ILegacySeoService,
    private notificationService: NotificationService,
    private store: Store,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit() {
    this.subscribeToRegionalConfigState();

    this.featureFlagService.getFeatureFlagValue(
        'enableDrivenToWinCategoryMenu',
        'feature_enable_driven_to_win_category_menu'
    ).then((flagValue)=>{
      this.drivenToWinCategoryMenuFFEnabled = flagValue;
    });

    this.seoService.addStructuredData([
      structuredDataBreadcrumbList([
        { name: 'Home', url: this.document.location.origin },
        { name: 'Menu', url: `${this.document.location.origin}/menu` }
      ])
    ]);
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  async navigateBasedOnFeatureFlag(configState: RegionalConfigurationFeatureState) {
    this.isLoading = true;

    //redirect to legacy
    if (
      configState?.regionalConfigurationOptions['feature_enable_global_menu']
        ?.value === 'false'
    ) {
      this.redirectService.redirectToLegacy('menu');
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const gms =
      (configState?.regionalConfigurationOptions as any)?.global_menu_slug ??
      '';
    if (gms.value) {
      this.storeInfo =
        await this.storeInfoWorkflowService.getStoreInfoBySlugWithoutSavingState(
          gms.value,
          'carryout'
        );
    } else this.notificationService.showError(UNKNOWN_ERROR);
    this.isLoading = false;
  }


  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
        this.navigateBasedOnFeatureFlag(state)
      })
    );
  }
}
